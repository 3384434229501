@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --bg-light-blue: #f1f5f8;
}

@layer components {
  /* form input classes */

  .error-outline {
    @apply border-2 border-solid border-red-400;
  }
  .login-input-field {
    @apply w-full p-2 transition duration-200 ease-in-out placeholder-gray-400 border focus:text-indigo-800 text-base rounded-lg outline-none px-4 focus:ring-indigo-400 focus:ring-1;
  }

  /* spinner classes */

  .loader {
    border-top-color: #5762c7 !important;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* curved sidenav classes */

  .unselected {
    @apply group p-1 pr-6 rounded-l-3xl hover:bg-indigo-700 hover:bg-opacity-30 hover:rounded-r-3xl;
  }

  .selected {
    position: relative;
    background: var(--bg-light-blue) !important;
    color: rgba(55, 48, 163);
  }
  .selected:hover {
    @apply rounded-r-none;
  }

  .selected:after,
  .selected:before {
    content: "";
    z-index: -1;
    @apply absolute w-6 h-6 right-0;
  }

  .selected:after {
    @apply -top-6;
    background: radial-gradient(
      circle at top left,
      currentColor 1.5rem,
      var(--bg-light-blue) 1.56rem
    );
  }

  .selected:before {
    @apply -bottom-6;
    background: radial-gradient(
      circle at bottom left,
      currentColor 1.5rem,
      var(--bg-light-blue) 1.56rem
    );
  }

  /* full-page-loader class */

  /* Absolute Center Spinner */
  .full-page-loader {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* Transparent Overlay */
  .full-page-loader:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    @apply bg-purple-500 bg-opacity-10;
  }
}
